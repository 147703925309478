import React, { useState, useEffect } from 'react';
import './TextDataDisplay.css';
import { TextData } from '../types/data';
import { Hierarchy, HierarchyNode } from '../types/hierarchy';
import { getHierarchy } from '../router/resources/getHierarchy';
import axios from 'axios';

interface TextDataDisplayProps {
  data?: TextData;
}

const TextDataDisplay: React.FC<TextDataDisplayProps> = ({ data }) => {
  const [hierarchy, setHierarchy] = useState<Hierarchy>({});
  const [correctStatus, setCorrectStatus] = useState<{ [key: string]: boolean }>({});
  const [userFeedback, setUserFeedback] = useState<{ [key: string]: { code: string, text: string } }>({});

  useEffect(() => {
    (window as any).userFeedback = userFeedback;
    (window as any).data = data;
    (window as any).correctStatus = correctStatus;
  }, [userFeedback]);

  useEffect(() => {
    getHierarchy()
      .then((fetchedHierarchy: Hierarchy) => {
        setHierarchy(fetchedHierarchy);
      })
      .catch((error) => console.error('Error fetching hierarchy:', error));
  }, []);

  useEffect(() => {
    const initialStatus: { [key: string]: boolean } = {};
    for (let i = 1; i <= 5; i++) {
      initialStatus[`level${i}`] = true;
    }
    setCorrectStatus(initialStatus);
  }, [data]);

  const handleCorrectClick = (level: string) => {
    const levelNumber = parseInt(level.replace('level', ''));

    const anyHigherIncorrect = Object.keys(correctStatus).some((key) => {
      const keyLevelNumber = parseInt(key.replace('level', ''));
      return keyLevelNumber < levelNumber && !correctStatus[key];
    });

    if (anyHigherIncorrect) {
      return;
    }

    setCorrectStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      for (let i = levelNumber; i <= 5; i++) {
        newStatus[`level${i}`] = true;
      }
      return newStatus;
    });

    setUserFeedback((prevFeedback) => {
      const newFeedback = { ...prevFeedback };
      for (let i = levelNumber; i <= 5; i++) {
        delete newFeedback[`level${i}`];
      }
      return newFeedback;
    });
  };

  const handleIncorrectClick = (level: string) => {
    const levelNumber = parseInt(level.replace('level', ''));

    setCorrectStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      newStatus[level] = false;
      for (let i = levelNumber + 1; i <= 5; i++) {
        newStatus[`level${i}`] = false;
      }
      return newStatus;
    });

    setUserFeedback((prevFeedback) => {
      const newFeedback = { ...prevFeedback };
      for (let i = levelNumber; i <= 5; i++) {
        delete newFeedback[`level${i}`];
      }
      return newFeedback;
    });
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLSelectElement>, level: string) => {
    const selectedOption = e.target.value;
    const [selectedCode, selectedText] = selectedOption.split('|');

    setUserFeedback((prevFeedback) => ({
      ...prevFeedback,
      [level]: { code: selectedCode, text: selectedText },
    }));
  };

  const getCode = (level: number) => {
    const levelKey = `level${level}`;
    const codeFromFeedback = correctStatus[levelKey] === false ? userFeedback[levelKey]?.code : undefined;
    const codeFromData = data?.[`system_code_level_${level}` as keyof TextData];
    return codeFromFeedback || codeFromData;
  };

  const filterLabels = (level: string): { code: string; label: string }[] => {
    let filteredLabels: { code: string; label: string }[] = [];
    const currentLevelNumber = parseInt(level.replace('level', ''));

        if (currentLevelNumber === 1) {
      filteredLabels = Object.keys(hierarchy).map((key) => {
        const childNode = hierarchy[key] as HierarchyNode;
        return { code: key, label: childNode.label };
      });
      return filteredLabels;
    }

    let parentNode: HierarchyNode | undefined;
    const rootCode = getCode(1);

    if (rootCode) {
      parentNode = hierarchy[rootCode as keyof Hierarchy];
    } else {
      return filteredLabels;
    }

    for (let i = 2; i < currentLevelNumber; i++) {
      const codeKey = getCode(i);
      if (codeKey && parentNode) {
        parentNode = parentNode[codeKey] as HierarchyNode | undefined;
        if (!parentNode) {
          break;
        }
      } else {
        break;
      }
    }

    if (parentNode) {
      filteredLabels = Object.keys(parentNode)
        .filter((key) => key !== 'label')
        .map((key) => {
          const childNode = parentNode![key] as HierarchyNode;
          return { code: key, label: childNode.label };
        });
    }

    return filteredLabels;
  };

  const handleSubmit = () => {
    if (data) {
      const feedbackData = {
        meldungsnummer: data.meldungsnummer,
        text: data.text,
        system_code_level_1: correctStatus['level1'] ? data.system_code_level_1 : (userFeedback['level1']?.code ?? null),
        system_code_level_2: correctStatus['level2'] ? data.system_code_level_2 : (userFeedback['level2']?.code ?? null),
        system_code_level_3: correctStatus['level3'] ? data.system_code_level_3 : (userFeedback['level3']?.code ?? null),
        system_code_level_4: correctStatus['level4'] ? data.system_code_level_4 : (userFeedback['level4']?.code ?? null),
        system_code_level_5: correctStatus['level5'] ? data.system_code_level_5 : (userFeedback['level5']?.code ?? null),
        system_text_level_1: correctStatus['level1'] ? data.system_text_level_1 : (userFeedback['level1']?.text ?? null),
        system_text_level_2: correctStatus['level2'] ? data.system_text_level_2 : (userFeedback['level2']?.text ?? null),
        system_text_level_3: correctStatus['level3'] ? data.system_text_level_3 : (userFeedback['level3']?.text ?? null),
        system_text_level_4: correctStatus['level4'] ? data.system_text_level_4 : (userFeedback['level4']?.text ?? null),
        system_text_level_5: correctStatus['level5'] ? data.system_text_level_5 : (userFeedback['level5']?.text ?? null),
        timestamp: new Date().toISOString(),
      };

      axios
        .post('https://backend.deployment-8d1c0b5a.sbb-dashboard.ivia.ch/api/v1/feedback', feedbackData)
        .then((response) => {
          console.log('Feedback submitted successfully:', response.data);
        })
        .catch((error) => {
          console.error('Error submitting feedback:', error);
        });
    }
  };

  return (
    <div className="textDataDisplay" style={{ overflowY: 'scroll' }}>
      {data ? (
        <div className="dataContainer">
          <div className="entry">{data.text}</div>
          <div className="systemDataRow">
            {['1', '2', '3', '4', '5'].map((level) => (
              <div key={level} className="levelRow">
                <div className="systemLevel">
                  <div className="dataRow">
                    <div className="dataRow">
                      <strong className="label">System Level {level}:</strong>
                      <div className="entry-inline">
                        {data[`system_code_level_${level}` as keyof TextData]} - {data[`system_text_level_${level}` as keyof TextData]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="correctButtons">
                  <div className="correctRow">
                    <div className="correctButtons">
                      <button
                        onClick={() => handleCorrectClick(`level${level}`)}
                        className={correctStatus[`level${level}`] ? 'active' : ''}
                      >
                        Correct
                      </button>
                      <button
                        onClick={() => handleIncorrectClick(`level${level}`)}
                        className={!correctStatus[`level${level}`] ? 'active' : ''}
                      >
                        Incorrect
                      </button>
                    </div>
                    {!correctStatus[`level${level}`] && (
                      <select
                        className='dropdown'
                        value={userFeedback[`level${level}`] ? `${userFeedback[`level${level}`].code}|${userFeedback[`level${level}`].text}` : ''}
                        onChange={(e) => handleFeedbackChange(e, `level${level}`)}
                      >
                        <option value="">-</option>
                        {filterLabels(`level${level}`).map((option) => (
                          <option key={option.code} value={`${option.code}|${option.label}`}>
                            {option.code} - {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="feedbackButton" onClick={handleSubmit}>Submit Feedback</button>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default TextDataDisplay;