import React, { useRef, useEffect } from 'react';
import { renderCollapsibleTree } from './CollapsibleTree';

interface TreeComponentProps {
    treeData: any;
    selectedMeldungsNummer: string;
    scatterData: any;  // Add scatterData as a prop
}

const TreeComponent: React.FC<TreeComponentProps> = ({
    treeData,
    selectedMeldungsNummer,
    scatterData,  // Add scatterData as a prop
}) => {
    const collapsibleTreeRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (collapsibleTreeRef.current) {
            renderCollapsibleTree(
                treeData,
                collapsibleTreeRef.current,
                selectedMeldungsNummer,
                scatterData  // Pass scatterData to the render function
            );
        }
    }, [treeData, selectedMeldungsNummer, scatterData]);  // Add scatterData to the dependency array

    return (
        <div>
            <div ref={collapsibleTreeRef}></div>
        </div>
    );
};

export default TreeComponent;