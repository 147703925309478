import * as d3 from 'd3';

export function renderScatterPlot(data, element, onPointSelect) {
    // Remove any existing SVG elements
    d3.select(element).selectAll("svg").remove();

    const containerWidth = element.clientWidth;
    const containerHeight = element.clientHeight;  // Match the container height

    const margin = { top: 20, right: 10, bottom: 50, left: 70 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const svg = d3.select(element).append("svg")
        .attr("width", containerWidth)
        .attr("height", containerHeight)
        .style("background", "white")
        .style("border-radius", "10px")
        .style("box-shadow", "0px 4px 8px rgba(0, 0, 0, 0.1)");

    const g = svg.append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

    // Calculate min and max for the domain, with padding
    const xMin = d3.min(data, d => d.max_pred);
    const xMax = d3.max(data, d => d.max_pred);
    const yMin = d3.min(data, d => d.similarity_score);
    const yMax = d3.max(data, d => d.similarity_score);

    const xPadding = (xMax - xMin) * 0.10; // 10% padding
    const yPadding = (yMax - yMin) * 0.10; // 10% padding

    const x = d3.scaleLinear()
        .domain([xMin - xPadding, xMax + xPadding])
        .range([0, width]);

    const y = d3.scaleLinear()
        .domain([yMin - yPadding, yMax + yPadding])
        .range([height, 0]);

    // Calculate a combined domain to ensure color diversity
    const combinedMin = Math.min(xMin, yMin) - xPadding;
    const combinedMax = Math.max(xMax, yMax) + yPadding;

    const colorInterpolator = d3.interpolateRgbBasis(["red", "orange", "green"]);

    const color = d3.scaleSequential(colorInterpolator)
        .domain([combinedMin, combinedMax]);

    // Add X Axis
    g.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x).ticks(10).tickFormat(d3.format(".2f")));

    // X Axis Label
    svg.append("text")
        .attr("class", "x-axis-label")
        .attr("text-anchor", "middle")
        .attr("x", margin.left + width / 2)
        .attr("y", height + margin.top + 40)
        .text("Max Prediction for current level");

    // Add Y Axis
    g.append("g")
        .call(d3.axisLeft(y).ticks(10).tickFormat(d3.format(".2f")));

    // Y Axis Label
    svg.append("text")
        .attr("class", "y-axis-label")
        .attr("text-anchor", "middle")
        .attr("x", -(margin.top + height / 2))
        .attr("y", margin.left - 40)
        .attr("transform", "rotate(-90)")
        .text("Similarity Score");

    // Optional: Add a note indicating axes are scaled
    svg.append("text")
        .attr("class", "axis-note")
        .attr("x", margin.left)
        .attr("y", margin.top - 10)
        .style("font-size", "12px")
        .style("fill", "#555")
        .text("Axes are scaled for clarity");

    // Use the shared tooltip
    const tooltip = d3.select(".tooltip");

    let selectedPoint = null;  // Track the currently selected point

    const circles = g.selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", d => x(d.max_pred))
        .attr("cy", d => y(d.similarity_score))
        .attr("r", 4)  // Smaller size for points
        .attr("fill", d => color((d.max_pred + d.similarity_score) / 2))  // Combine x and y for color
        .attr("opacity", 0.8)  // Make points slightly transparent for overlap visibility
        .on("mouseover", function (event, d) {
            if (selectedPoint !== this) {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("r", 8);  // Increase size on hover
            }

            tooltip.style("visibility", "visible")
                .text(`Max Pred: ${d.max_pred}, Similarity: ${d.similarity_score}, ID: ${d.meldungsnummer}`);
        })
        .on("mousemove", (event) => {
            tooltip.style("top", `${event.pageY - 10}px`)
                .style("left", `${event.pageX + 10}px`);
        })
        .on("mouseout", function () {
            if (selectedPoint !== this) {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("r", 4);  // Reset size on mouse out
            }

            tooltip.style("visibility", "hidden");
        })
        .on("click", function (event, d) {
            if (selectedPoint) {
                // Stop the blinking on the previously selected point
                d3.select(selectedPoint)
                .interrupt()  // Stop ongoing transitions
                .transition()
                .duration(200)
                .attr("r", 4);  // Reset size
        }

        // Mark the current point as selected
        selectedPoint = this;

        function blink() {
            d3.select(selectedPoint)
                .transition()
                .duration(300)
                .attr("r", 9)  // Increase size
                .transition()
                .duration(500)
                .attr("r", 4)  // Decrease size
                .on("end", blink);  // Repeat
        }

        blink();  // Start blinking

        // Remove the tooltip when a new point is clicked
        tooltip.style("visibility", "hidden");

        // Handle point selection
        onPointSelect(d.meldungsnummer);
    });
}