import * as d3 from 'd3';

export function renderCollapsibleTree(data, element, selectedMeldungsNummer, scatterData) {
  if (!data) {
    console.error('No data provided for the collapsible tree. Exiting function.');
    return;
  }

  function truncateText(text, maxLength) {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }

  d3.select(element).selectAll("svg").remove();

  const width = element.clientWidth;
  const marginTop = 30;
  const marginRight = 10;
  const marginBottom = 10;
  const marginLeft = 50;

  const root = d3.hierarchy(data);

  const dx = 50;
  const dy = (width - marginRight - marginLeft + 100) / (1 + root.height);

  const tree = d3.tree().nodeSize([dx, dy]);
  const diagonal = d3.linkHorizontal().x(d => d.y).y(d => d.x);

  const svg = d3.select(element).append("svg")
    .attr("width", width)
    .attr("height", dx)
    .attr("viewBox", [-marginLeft, -marginTop, width, dx])
    .attr("style", "max-width: 100%; height: auto; font: 12px sans-serif; user-select: none;");

  const gLink = svg.append("g")
    .attr("fill", "none")
    .attr("stroke", "#555")
    .attr("stroke-opacity", 0.4)
    .attr("stroke-width", 1.5);

  const gNode = svg.append("g")
    .attr("cursor", "pointer")
    .attr("pointer-events", "all");

  const tooltip = d3.select("body").append("div")
    .attr("class", "tooltip")
    .style("position", "absolute")
    .style("visibility", "hidden")
    .style("background", "#f9f9f9")
    .style("padding", "5px")
    .style("border-radius", "4px")
    .style("box-shadow", "0px 0px 10px rgba(0, 0, 0, 0.1)");

  // Define the size scale
  const sizeScale = d3.scaleLog()
    .domain([1, d3.max(root.descendants(), d => d.data.count) || 1])
    .range([5, 10]);  // Adjust this range for the desired node sizes

  // Define the greyness scale
  const greynessScale = d3.scaleLinear()
    .domain([1, d3.max(root.descendants(), d => d.data.count) || 1])
    .range(["#cccccc", "#555555"]);  // Light grey to dark grey

  // Color scale for edge probabilities
  const colorScale = d3.scaleSequential(d3.interpolateRgb("#cccccc", "#e20000"))
    .domain([0, 1]);

  // Function to get the probability for a given node name from scatterData
  function getProbabilityForNode(nodeName) {
    const scatterEntry = scatterData.find(d => d.meldungsnummer === selectedMeldungsNummer);
    return scatterEntry ? scatterEntry[nodeName] : null;
  }

  function update(event, source) {
    const duration = event?.altKey ? 2500 : 250;
    const nodes = root.descendants().reverse();
    const links = root.links();

    tree(root);

    let left = root;
    let right = root;
    root.eachBefore(node => {
      if (node.x < left.x) left = node;
      if (node.x > right.x) right = node;
    });

    const height = right.x - left.x + marginTop + marginBottom;

    const transition = svg.transition()
      .duration(duration)
      .attr("height", height)
      .attr("viewBox", [-marginLeft, left.x - marginTop, width, height])
      .tween("resize", window.ResizeObserver ? null : () => () => svg.dispatch("toggle"));

    const node = gNode.selectAll("g")
      .data(nodes, d => d.id);

    const nodeEnter = node.enter().append("g")
      .attr("transform", d => `translate(${source.y0},${source.x0})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .on("click", (event, d) => {
        d.children = d.children ? null : d._children;
        update(event, d);
      })
      .on("mouseover", (event, d) => {
        if (d.depth !== 0) {  // Disable tooltip for root
          tooltip.text(`${d.data.name} (Occurrences: ${d.data.count})`)
            .style("visibility", "visible");
        }
      })
      .on("mousemove", (event) => {
        tooltip.style("top", (event.pageY - 10) + "px")
          .style("left", (event.pageX + 10) + "px");
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    nodeEnter.each(function (d) {
      if (d.depth === 0) {
        // Root node: use a square instead of a circle
        d3.select(this).append("rect")
          .attr("width", 12)
          .attr("height", 12)
          .attr("x", -6)  // Centers the square on the node position
          .attr("y", -6)  // Centers the square on the node position
          .attr("fill", "#555")
          .attr("fill-opacity", 1)
          .attr("stroke-width", 0);  // No borders for the root node
      } else {
        // Regular nodes: use circles
        d3.select(this).append("circle")
          .attr("r", d => d.data.count > 0 ? sizeScale(d.data.count) : 5)  // Adjust the size based on occurrences
          .attr("fill", d => d.data.count > 0 ? greynessScale(d.data.count) : "#ff0000")  // Scale greyness, red for 0 occurrences
          .attr("stroke-width", 0);  // No borders

        // Add a centered dot if the node has children
        if (d._children || d.children) {
          d3.select(this).append("circle")
            .attr("r", 2)  // Small dot in the center
            .attr("fill", "white");
        }
      }
    });

    nodeEnter.append("text")
      .attr("dy", "-1.1em")  // Move the text 1.5em units above the node
      .attr("x", -30)  // Keep the text aligned horizontally with the node
      // .attr("text-anchor", "left")  // Align the text to the middle of the node
      .text(d => d.depth === 0 ? selectedMeldungsNummer : truncateText(d.data.name, 20))
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 3)
      .attr("stroke", "white")
      .attr("paint-order", "stroke");

    const nodeUpdate = node.merge(nodeEnter).transition(transition)
      .attr("transform", d => `translate(${d.y},${d.x})`)
      .attr("fill-opacity", 1)
      .attr("stroke-opacity", 1);

    const nodeExit = node.exit().transition(transition).remove()
      .attr("transform", d => `translate(${source.y},${source.x})`)
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0);

    const link = gLink.selectAll("path")
      .data(links, d => d.target.id);

    const linkEnter = link.enter().append("path")
      .attr("d", d => {
        const o = { x: source.x0, y: source.y0 };
        return diagonal({ source: o, target: o });
      })
      .on("mouseover", function (event, d) {  // Add mouseover event for links
        const prob = getProbabilityForNode(d.target.data.name);
        if (prob !== null) {
          tooltip.text(`Probability: ${prob.toFixed(2)}`)
            .style("visibility", "visible");
        }
      })
      .on("mousemove", function (event) {
        tooltip.style("top", (event.pageY - 10) + "px")
          .style("left", (event.pageX + 10) + "px");
      })
      .on("mouseout", function () {
        tooltip.style("visibility", "hidden");
      });

    link.merge(linkEnter).transition(transition)
      .attr("d", diagonal)
      .attr("stroke-width", d => {
        const prob = getProbabilityForNode(d.target.data.name);
        return prob ? Math.max(1.5, prob * 3) : 1.5;  // Adjust the thickness based on probability
      })
      .attr("stroke", d => {
        const prob = getProbabilityForNode(d.target.data.name);
        return prob !== null ? colorScale(prob) : "#555";  // Apply the color based on probability
      })
      .attr("stroke-opacity", 1);  // Set a fixed opacity of 1 since we are using color instead of opacity

    link.exit().transition(transition).remove()
      .attr("d", d => {
        const o = { x: source.x, y: source.y };
        return diagonal({ source: o, target: o });
      });

    root.eachBefore(d => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  // Collapse all nodes deeper than level 2 (depth > 1)
  function collapse(d) {
    if (d.children) {
      d.children.forEach(collapse);
      if (d.depth > 1) {
        d._children = d.children;
        d.children = null;
      }
    }
  }

  root.x0 = dy / 2;
  root.y0 = 0;
  root.descendants().forEach((d, i) => {
    d.id = i;
    d._children = d.children;
    if (d.depth > 1) d.children = null; // Collapse by default if depth > 1
  });

  root.children.forEach(collapse); // Collapse nodes below level 2

  update(null, root);
}