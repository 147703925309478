import React from 'react';
import './Legend.css';

const Legend = () => {
    return (
        <div className="legend-container">
            <div className="legend-item">
                <div className="legend-symbol-container">
                    <span className="legend-circle high-occurrence"></span>
                    <span className="legend-text">High occurrence</span>
                </div>
                <div className="legend-symbol-container">
                    <span className="legend-circle low-occurrence"></span>
                    <span className="legend-text">Low occurrence</span>
                </div>
                <div className="legend-symbol-container">
                    <span className="legend-circle no-occurrence"></span>
                    <span className="legend-text">No occurrence</span>
                </div>
                <div className="legend-symbol-container">
                    <span className="legend-dot">•</span>
                    <span className="legend-text">Node can be expanded/collapsed</span>
                </div>
            </div>
        </div>
    );
};

export default Legend;