import React, { useRef, useEffect, useState } from 'react';
import { renderScatterPlot } from './ScatterPlot';

interface ScatterPlotComponentProps {
    scatterData: any;
    onPointSelect: (meldungsNummer: string) => void;
}

const ScatterPlotComponent: React.FC<ScatterPlotComponentProps> = ({
    scatterData,
    onPointSelect,
}) => {
    const scatterPlotRef = useRef(null);
    const [selectedPoint, setSelectedPoint] = useState(null);

    useEffect(() => {
        if (scatterData && scatterPlotRef.current) {
            // Calculate max_pred here before rendering
            const processedData = scatterData.map((d: any) => ({
                ...d,
                max_pred: Math.max(+d['90 - Einstiegssysteme'],
                    +d['150 - Neigetechnik / Wankkompensation (Wako)'],  // Ensure exact key names are used
                    +d['160 - DG / Fahrwerk & Spurführung'])
            }));
            renderScatterPlot(processedData, scatterPlotRef.current, setSelectedPoint);
        }
    }, [scatterData]);

    useEffect(() => {
        if (selectedPoint) {
            onPointSelect(selectedPoint); // Update the selected meldungsnummer
        }
    }, [selectedPoint, onPointSelect]);

    return (
        <div className="scatter-plot-container" ref={scatterPlotRef}></div>
    );
};

export default ScatterPlotComponent;