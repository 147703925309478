import axiosClient from '../apiClient';

export function getHierarchy(): Promise<any> {
  const url = `https://backend.deployment-8d1c0b5a.sbb-dashboard.ivia.ch/api/v1/labels`;
  const promise = axiosClient.get(url);
  return promise
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error fetching labels:", err);
      throw err;
    });
}