import { useEffect, useState } from 'react';
import './App.css';
import { TextData } from './types/data';
import { getTextData } from './router/resources/textData';
import { getScatterData } from './router/resources/scatterData';
import TextDataDisplay from './components/TextDataDisplay';
import ScatterPlotComponent from './components/ScatterPlotComponent';
import TreeComponent from './components/TreeComponent';
import ethLogo from './assets/eth_logo.png';
import sbbLogo from './assets/sbb_logo.png';
import iviaLogo from './assets/ivia_logo.png';
import hierarchyData from './assets/hierarchy.json';
import Legend from './components/Legend';
import * as d3 from 'd3';

function App() {
  const [meldungsNummer, setMeldungsNummer] = useState<string>('129989028');
  const [textData, setTextData] = useState<TextData | undefined>();
  const [scatterData, setScatterData] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any>(null);

  useEffect(() => {
    if (meldungsNummer) {
      getTextData(meldungsNummer).then((fetchedData) => {
        setTextData(fetchedData);
      });

      const updatedTreeData = JSON.parse(JSON.stringify(hierarchyData));

      if (updatedTreeData.name === '§meldungsNummer') {
        updatedTreeData.name = meldungsNummer;
      }

      setTreeData(updatedTreeData);
    }
  }, [meldungsNummer]);

  useEffect(() => {
    const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip");

    // Adding tooltips for section titles
    d3.selectAll(".section-title")
      .on("mouseover", function (event, d) {
        const titleText = event.currentTarget.textContent;
        let tooltipText = "";

        switch (titleText) {
          case "Prediction Distribution":
            tooltipText = "This chart shows the distribution of model predictions across different similarity scores.";
            break;
          case "Training Corpus Hierarchy Overview":
            tooltipText = "This tree displays an overview of the training corpus used by the model, showing the frequency of system codes.";
            break;
          case `Model Feedback for Meldungsnummer: ${meldungsNummer}`:
            tooltipText = "This section allows you to provide feedback on the model's prediction for the selected Meldungsnummer.";
            break;
          default:
            tooltipText = "";
            break;
        }

        tooltip.style("visibility", "visible")
          .text(tooltipText)
          .style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mousemove", function (event) {
        tooltip.style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mouseout", function () {
        tooltip.style("visibility", "hidden");
      });

  }, [meldungsNummer]);

  useEffect(() => {
    getScatterData().then((fetchedData) => {
      console.log("Fetched scatter data:", fetchedData); // Add this line
      setScatterData(fetchedData);
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={ethLogo} alt="ETH Logo" className="logo" />
          <img src={iviaLogo} alt="IVIA Logo" className="logo" />
          <img src={sbbLogo} alt="SBB Logo" className="logo" />
        </div>
      </header>
      <div className="main-content">
        <div className="column">
          <h2 className="section-title">
            Prediction Distribution
          </h2>
          <div className="scatter-plot-container">
            <ScatterPlotComponent
              scatterData={scatterData}
              onPointSelect={setMeldungsNummer}
            />
          </div>
          <h2 className="section-title">
            Model Feedback for Meldungsnummer: {meldungsNummer}
          </h2>
          <div className="textdata-container">
            <TextDataDisplay data={textData} />
          </div>
        </div>
        <div className="column">
          <div className="tree-container">
            <h2 className="section-title">
              Training Corpus Hierarchy Overview
            </h2>
            <TreeComponent
              treeData={treeData}
              selectedMeldungsNummer={meldungsNummer}
              scatterData={scatterData}
            />
          </div>
          <Legend /> {/* Legend is already placed correctly here */}
        </div>
      </div>
    </div>
  );
}

export default App;