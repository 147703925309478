import { TextData } from '../../types/data';
import axiosClient from '../apiClient';

/**
 * Get the text data through a GET request
 * @param id the identifier of the text data file
 */
export function getTextData(id: string): Promise<TextData | undefined> {
  const url = `https://backend.deployment-8d1c0b5a.sbb-dashboard.ivia.ch/api/v1/textdata/${id}`; // Ensure the port matches your backend
  console.log(url)
  const promise = axiosClient.get<TextData>(url);
  return promise
    .then((res) => {
      if (res.status !== 204) {
        return res.data;
      }
      return undefined;
    })
    .catch((err) => {
      console.error("Error fetching text data:", err);
      throw err;
    });
}