import axiosClient from '../apiClient';

/**
 * Get the scatter data through a GET request
 */
export function getScatterData(): Promise<any> {
  const url = `https://backend.deployment-8d1c0b5a.sbb-dashboard.ivia.ch/api/v1/scatterdata`;
  console.log(url);
  return axiosClient.get(url)
    .then((res) => {
      if (res.status !== 204) {
        console.log("Fetched Scatter Data:", res.data.data); // Add this line
        return res.data.data;
      }
      return undefined;
    })
    .catch((err) => {
      console.error("Error fetching scatter data:", err);
      throw err;
    });
}